.Hero {
  display: flex;
  justify-content: space-between;
}

.left-h {
  padding: 1rem 2rem 1.5rem 2rem;
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.right-h {
  flex: 1;
  position: relative;
  background-color: var(--orange);
}

.the-best-ad {
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hero-blur{
  width: 22rem;
  height: 30rem;
  left: 0;
}

.the-best-ad > div {
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}

.the-best-ad > span {
  z-index: 2;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}

.hero-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 60%;
  font-style: italic;
}

.figures {
  display: flex;
  gap: 2rem;
}

.figures > div {
  display: flex;
  flex-direction: column;
  width: 7rem;
  
}

.figures > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem; 
}

.figures > div > span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}

.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.hero-buttons>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 8rem;
}

.hero-buttons>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}

.right-h>.btn{
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
}

.p-rate{
    display: flex;
    /* flex-direction: column; */
    gap: 2rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    border-radius: 5px;
    /* align-items: start; */
    position: absolute;
    right: 2rem;
    top: 7rem;
}

.p-rate>:nth-child(2){
  color: var(--gray);
  font-weight: bold;
  /* font-style: italic; */
  text-transform: uppercase;
  font-size: 1rem;
}



.p-rate>div{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.hero-press{
  position: absolute;
  top: 20rem;
  right: 8rem;
  width: 45rem;
}


.vouchers{
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  top: 35rem;
  right: 55rem;
}

.vouchers>img{
  width: 3rem;
}

.vouchers>div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vouchers>div>:nth-child(1){
  color: var(--gray);
}

.vouchers>div>:nth-child(2){
  color: white;
}

@media screen and (max-width:768px){
  .Hero {
    flex-direction: column;
  }
  .hero-blur{
    width: 14rem;
  }
  .the-best-ad {
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }
  .hero-text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;   
  }

  .hero-text > div:nth-of-type(3) {
    width: 100%;
    font-size: small;
    font-weight: 200;
    text-align: center;
    letter-spacing: 1px;
  }



  .figures > div > span:nth-of-type(1) {
    font-size: large;
  }

  .figures > div > span:nth-of-type(2) {
    font-size: x-small;
  }

  .right-h{
    position: relative;
    background: none;
  }

  .p-rate{
    left: 1rem;
    top: 1rem;
  }

  .vouchers{
    position: relative;
    top: 12rem;
    left: 2rem;
  }
  .vouchers>img{
    width: 2rem;
  }
  .vouchers>div>:nth-child(2){
    color: white;
    font-size: 1rem;
  }

  .hero-press{
    position: relative;
    width: 18rem;
    left: 2rem;
    top: 12rem;
    align-self: start;
  }


  .right-h>.btn{
    z-index: 9;
    position: absolute;
    top: -0.2rem;
    
  
   
  }
}