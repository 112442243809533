.Reasons{
    padding: 1rem 2rem;
    display: flex;
    gap: 2rem;
}

.left-side{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;

}

.left-side>img{
    object-fit: cover;
}

.right-side{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-side>span{
    font-weight: bold;
    color: var(--orange);
}

.right-side>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}

.details-r{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 20px 0;
}

.details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}

.details-r>div>img{
width: 2rem;
height: 2rem;
}


@media screen and (max-width:768px){
    .Reasons{
        flex-direction: column;
    }
    .left-side{
        grid-auto-rows: auto;
        overflow: hidden;
    }

    .left-side > :nth-child(1){
        width: 130px !important;
        height: 170px !important;
        object-fit: fill;
    }
    .left-side > :nth-child(2){
        width: 130px !important;
        height: 170px !important;
        object-fit: fill;

    }
    .left-side > :nth-child(3){
        width: 130px !important;
        height: 150px !important;
        object-fit: cover;
        object-position: bottom;


    }
    .left-side > :nth-child(4){
        width: 130px !important;
        height: 150px !important;
        object-fit: fill;

    }

    .right-side>.why-choose {
        display: flex;
        flex-direction: column;
    }
}
